import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import CircularProgress from '../components/CircularProgress';




const DATA = [
    {
        id: 0,
        image: 'images/products/4.png',
        header1: 'Fırın Serisi',
        header2: 'Turuncu Ekmeklik',
        type: 'Fırın Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 25,
            kül: 70,
            energy: 10,
            gluten: 40,
            sed: 89,
            stab: 60
        },

    },




    {
        id: 1,
        image: 'images/products/10.png',
        header1: 'Fırın Serisi',
        header2: 'Yeşil Ekmeklik',
        type: 'Fırın Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 56,
            kül: 34,
            energy: 43,
            gluten: 76,
            sed: 20,
            stab: 43
        },

    },
    {
        id: 2,
        image: 'images/products/3.png',
        header1: 'Fırın Serisi',
        header2: 'Kırmızı Ekmeklik',
        type: 'Fırın Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 40,
            kül: 45,
            energy: 46,
            gluten: 38,
            sed: 89,
            stab: 74
        },

    },
    {
        id: 3,
        image: 'images/products/5.png',
        header1: 'Fırın Serisi',
        header2: 'Sarı Ekmeklik',
        type: 'Fırın Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 67,
            kül: 37,
            energy: 45,
            gluten: 68,
            sed: 97,
            stab: 28
        },

    },
    {
        id: 4,
        image: 'images/products/6.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Kuru Kek, Pasta',
        type: 'Pastane Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 40,
            kül: 60,
            energy: 96,
            gluten: 40,
            sed: 53,
            stab: 54
        },

    },
    {
        id: 5,
        image: 'images/products/11.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Poğaça, Açma, Börek',
        type: 'Pastane Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 50,
            kül: 60,
            energy: 50,
            gluten: 40,
            sed: 60,
            stab: 55
        },

    },



    {
        id: 6,
        image: 'images/general/22.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Unlu Mamüller Pasta-Börek',
        type: 'Pastane Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 53,
            kül: 58,
            energy: 86,
            gluten: 75,
            sed: 55,
            stab: 10
        },

    },

    {
        id: 7,
        image: 'images/products/2.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Pidelik',
        type: 'Usta Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 49,
            kül: 99,
            energy: 100,
            gluten: 80,
            sed: 30,
            stab: 70
        },

    },

    // {
    //     id: 8,
    //     image: 'images/general/22.png',
    //     header1: 'Özel Amaçlı Buğday Unu',
    //     header2: 'Baklavalık ve Böreklik',
    //     type: 'Lüks Serisi',
    //     content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


    //     protein: '10,5(min)',
    //     kül: '0,7-0,8',
    //     moisture: '14,5',
    //     pdf: '#',

    //     ibre:
    //     {
    //         protein: 62,
    //         kül: 45,
    //         energy: 77,
    //         gluten: 13,
    //         sed: 49,
    //         stab: 33
    //     },

    // },

    {
        id: 9,
        image: 'images/products/1.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Yufkalık',
        type: 'Yöresel Seri',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 62,
            kül: 45,
            energy: 77,
            gluten: 13,
            sed: 49,
            stab: 33
        },

    },

    {
        id: 10,
        image: 'images/products/4.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Tam Buğday Unu',
        type: 'Yöresel Seri',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 62,
            kül: 45,
            energy: 77,
            gluten: 13,
            sed: 49,
            stab: 33
        },

    },

]












const Home38 = () => {
    const [toggle, setToggle] = useState(0)








    return (
        <div className=' pt-20 md:pt-28   flex flex-col justify-between  ' style={{background:"rgb(69,40,0)",background:"radial-gradient(circle, rgba(69,40,0,1) 0%, rgba(0,0,0,1) 100%)"}}>

            {
                DATA.map((item) => {
                    return (
                        toggle === item.id && <div >
                            <div className='space-y-20 px-8 md:px-16 lg:px-36 3xl:container mx-auto py-10'>

                                <div className='text-[0.875rem] text-white'>{`Ürünler / ${item.type} /${item.header1} / ${item.header2}`}</div>

                                <div className='h-full  flex flex-col lg:flex-row'>

                                    <div className='flex  pb-20 lg:pb-0 lg:w-1/2 '>
                                        <div className='flex flex-col  space-y-2   min-w-max '>
                                            <div className='flex flex-col space-y-2'>
                                                <div className={`text-base lg:text-2xl text-white cursor-pointer hover:scale-105 transition-transform`} onClick={() => setToggle(0)}>Fırın Serisi</div>
                                                <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform  ${toggle === 0 ? 'opacity-100' : 'opacity-60'} ${toggle === 0 ? 'scale-105' : 'scale-100'} }`} onClick={() => setToggle(0)}>Turuncu Ekmeklik</div>
                                                <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 1 ? 'opacity-100' : 'opacity-60'} ${toggle === 1 ? 'scale-105' : 'scale-100'}`} onClick={() => setToggle(1)}>Yeşil Ekmeklik</div>
                                                <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 2 ? 'opacity-100' : 'opacity-60'} ${toggle === 2 ? 'scale-105' : 'scale-100'}`} onClick={() => setToggle(2)}>Kırmızı Ekmeklik</div>
                                                <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 3 ? 'opacity-100' : 'opacity-60'} ${toggle === 3 ? 'scale-105' : 'scale-100'}`} onClick={() => setToggle(3)}>Sarı Ekmeklik</div>
                                                {/* <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 4 ? 'scale-105' : 'scale-100'}`} onClick={() => setToggle(4)}>Tam Buğday Unu</div> */}
                                            </div>
                                            <div className='flex flex-col space-y-2'>
                                                <div className={`text-base lg:text-2xl text-white cursor-pointer hover:scale-105 transition-transform`} onClick={() => setToggle(4)}>Pastane Serisi</div>
                                                <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 4 ? 'opacity-100' : 'opacity-60'}  ${toggle === 4 ? 'scale-105' : 'scale-100'}`} onClick={() => setToggle(4)}>Kuru Kek, Pasta</div>
                                                <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 5 ? 'opacity-100' : 'opacity-60'} ${toggle === 5 ? 'scale-105' : 'scale-100'}`} onClick={() => setToggle(5)}>Poğaça, Açma, Börek</div>
                                                <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 6 ? 'opacity-100' : 'opacity-60'} ${toggle === 6 ? 'scale-105' : 'scale-100'}`} onClick={() => setToggle(6)}>Mamüller Pasta-Börek</div>

                                            </div>
                                            <div className='flex flex-col space-y-2'>
                                                <div className={`text-base lg:text-2xl text-white cursor-pointer hover:scale-105 transition-transform  `} onClick={() => setToggle(7)}>Usta Serisi</div>
                                                <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 7 ? 'opacity-100' : 'opacity-60'}  ${toggle === 7 ? 'scale-105' : 'scale-100'}`} onClick={() => setToggle(7)}>Pidelik</div>
                                            </div>
                                            {/* <div className='flex flex-col'>
                                                <div className={`text-base lg:text-2xl text-white cursor-pointer hover:scale-105 transition-transform `} onClick={() => setToggle(8)}>Lüks Serisi</div>
                                                <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 8 ? 'opacity-100' : 'opacity-60'}  ${toggle === 8 ? 'scale-105' : 'scale-100'}`} onClick={() => setToggle(8)}>Baklavalık ve Böreklik</div>
                                            </div> */}
                                            <div className='flex flex-col'>
                                                <div className={`text-base lg:text-2xl text-white cursor-pointer hover:scale-105 transition-transform `} onClick={() => setToggle(9)}>Yöresel Seri</div>
                                                <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 9 ? 'opacity-100' : 'opacity-60'}  ${toggle === 9 ? 'scale-105' : 'scale-100'}`} onClick={() => setToggle(9)}>Yufkalık</div>
                                                <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 10 ? 'opacity-100' : 'opacity-60'}  ${toggle === 10 ? 'scale-105' : 'scale-100'}`} onClick={() => setToggle(10)}>Tam Buğday Unu</div>
                                            </div>

                                        </div>

                                        <div className=' w-full  flex px:0 2xl:px-28 '>
                                            <img src={`${item.image}`} className="animate-changeOpacity w-full object-contain object-top" alt="logo" />
                                        </div>
                                    </div>


                                    <div className='flex    '>
                                        <div className='flex flex-col space-y-10'>
                                        <div className='flex flex-col'>
                                            <h5 className='text-[3.375rem] font-bold leading-none text-white animate-changeOpacity1' >{item.header1}</h5>
                                            <h5 className='text-[3.375rem] leading-none text-white animate-changeOpacity1'>{item.header2}</h5>

                                        </div>
                                        <div className='w-full flex flex-col items-start  space-y-5 '>
                                            <div className='flex  w-full justify-start space-x-5 animate-changeOpacity1'>
                                                <CircularProgress progressPercentage={item.ibre.protein} name={'PROTEIN'} />
                                                <CircularProgress progressPercentage={item.ibre.kül} name={'KÜL %'} />
                                                <CircularProgress progressPercentage={item.ibre.energy} name={'ENERJİ'} />
                                            </div>

                                            <div className='flex  w-full justify-start space-x-5 animate-changeOpacity1'>
                                                <CircularProgress progressPercentage={item.ibre.gluten} name={'GLUTEN'} />
                                                <CircularProgress progressPercentage={item.ibre.sed} name={'SEDİMANTASYON'} />
                                                <CircularProgress progressPercentage={item.ibre.stab} name={'STABİLİTE'} />
                                            </div>

                                        </div>
                                        </div>


           
                                    </div>
                                    <div className='cursor-pointer  transition-transform  flex-1 flex items-end justify-end '>
                                                    <img src="images/products/pdf.svg" className="w-16 lg:w-auto animate-contentIcons4 hover:scale-105 duration-300" alt="logo" />

                                            </div>
                                </div>

                                <div className='w-full    flex justify-center text-white animate-changeOpacity1'>
                                    <h5 className='w-1/2'>Lorem ipsum dolor sit ametsed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</h5>
                                </div>

                            </div>






                        </div>
                    )
                })
            }


















            <div className='mt-20'>
                <img src="images/general/XV4A0710.jpg" className=" " alt="logo" />
            </div>




        </div>
    )
}

export default Home38