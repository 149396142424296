import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Home40 = () => {
  return (
    <div>
      
    <div className='w-full  bg-black space-y-16 pt-20 md:pt-44'>
      <div className='w-full flex flex-col items-center space-y-14 px-8   md:px-40 lg:px-40'>
        <h3 className='text-white text-[2.75rem]'>Araştırma Ve Geliştirme</h3>
        <h3 className='text-white text-[2.75rem]'>Doğaya Uygulanan Teknoloji</h3>
        <div>
          <h6 className='text-white text-sm text-center' >Reolojik kalite, plastisite ve fermantasyon özellikleri ve mikrobiyolojik parametrelere uygunluk:</h6>
          <h6 className='text-white text-sm text-center' >

            Fırıncılık profesyonelleri için güvenilir ve teknik açıdan performanslı bir ürün için Molino Pordenone, yüzyılı aşkın süredir edinilen zengin bir uzmanlığa sahiptir. Bu zengin deneyim sayesinde, mevsimlere ve hasatlara göre özellikleri değişen canlı bir malzeme olan buğdayın değişkenliğini yönetebiliyor ve tutarlı kalitede bir ürün garanti edebiliyoruz.
          </h6>
        </div>
      </div>

      <div className='w-full h-[947px] overflow-hidden'>
        <img
          src="images/general/XV4A0561.jpg"
          alt="logo"
          className=" w-full h-full object-center object-cover  "
        />
      </div>

      <div className='w-full h-[947px] overflow-hidden'>
        <img
          src="images/general/XV4A0572.jpg"
          alt="logo"
          className=" w-full h-full object-center object-cover  "
        />
      </div>

      <div className='w-full flex flex-col items-center space-y-14 px-8  md:px-40 lg:px-40'>
        
        <h3 className='text-white text-[2.75rem]'>Özel Yapım: Endüstriye Yönelik Unlar</h3>
        <div>
          <h6 className='text-white text-sm text-center' >Molino Pordenone, endüstriye yönelik unlar için kişiselleştirilmiş bir geliştirme hizmeti sağlamak üzere kuruldu. Uzman teknisyenlerin bilgi birikimi ve laboratuvar teknolojileri sayesinde, müşterinin talep ettiği bitmiş ürün ve sistem gereksinimlerine özel çözümler üretebilmekteyiz. Ürünün ince ayarını yapmayı amaçlayan tüm araştırma ve geliştirme süreci, müşteriyle yakın işbirliği içinde gerçekleştirilir: ortaklığımızı güçlendiren ve gerçekten kişiye özel bir ürünle sonuçlanan bir danışmanlık hizmeti.</h6>
        </div>
      </div>

      <div className='w-full h-[947px] overflow-hidden'>
        <img
          src="images/general/XV4A0695.jpg"
          alt="logo"
          className=" w-full h-full object-center object-cover  "
        />
      </div>

      
    </div>
    
    </div>
  )
}

export default Home40