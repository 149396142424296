import React from 'react'
import Header from './Header'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'

const Menu = ({ menuType, setMenuType }) => {
    const [menuToggle, setMenuToggle] = useState(false)

    useGSAP(
        () => {
            gsap.from(".menu", {

        
                duration: 1, 
                opacity:0
        
              });

              gsap.from(".menu-li-1", {

        
                duration: 3, 
                opacity:0,
                delay:0.25
        
              });

              gsap.from(".menu-li-2", {

        
                duration: 3, 
                opacity:0,
                delay:0.5
        
              });

              gsap.from(".menu-li-3", {

        
                duration: 3, 
                opacity:0,
                delay:0.75
        
              });

              gsap.from(".menu-li-4", {

        
                duration: 3, 
                opacity:0,
                delay:1
        
              });

              gsap.from(".menu-li-5", {

        
                duration: 3, 
                opacity:0,
                delay:1.25
        
              });

              gsap.from(".menu-li-6", {

        
                duration: 3, 
                opacity:0,
                delay:1.50
        
              });

              gsap.from(".cross-1", {

        
                duration: 3, 
                opacity:0,
                delay:1.50
        
              });

              


              



         

        })
    return (

        <div data-lenis-prevent className={`h-screen overflow-x-hidden  w-full   z-30 menu   ${menuType ? 'fixed' : 'hidden'}`}>
            <div className={` overflow-y-auto  px-8 md:px-16 lg:px-36 h-[calc(100vh-5rem)] md:h-[calc(100vh-7rem)]    top-[5rem] md:top-[7rem]  w-full relative   z-40  grid grid-cols-1  md:grid-cols-3 py-10 `}>
                <div className='pt-10  order-last md:order-none flex flex-col  space-y-10'>
                    <h5 className='text-lg font-bold text-[#707070] menu-li-1'>Dünyamız </h5>
                    <Link to={'home40'}><h5 className='text-lg font-bold text-white menu-li-2'>Araştırma Ve Geliştirme</h5></Link>
                    <h5 className='text-lg font-bold text-white menu-li-3'>LabOratuvar Profesyonellerinin Yanında</h5>
                    <Link to={'home33'}><h5  className='text-lg font-bold text-white menu-li-4'>Gıda Sektöründeki Çalışmalarımız</h5></Link>
                    <h5 className='text-lg font-bold text-white menu-li-3'>Gıda Güvenliği Sistemi</h5>
                    <Link to={{pathname: 'home41', hash: '#section1'}}><h5 className='text-lg font-bold text-white menu-li-5'>Buğday</h5></Link>
                    <Link to={'Iletisim'}><h5 className='text-lg font-bold text-white menu-li-6'>İletişim</h5></Link>
                </div>


                <div className=' flex pt-10  order-1 md:order-none'>
                    <div className=' flex flex-col  w-full  '>

                        <h5 className='text-lg font-bold text-[#707070]'>Bizim Ürünlerimiz</h5>

                        <div className='flex justify-between items-center border-b border-[#707070]'>
                            <div className=' flex items-center space-x-5  w-full py-3'>
                                <img className='h-12 w-12' src="images/menu/menu-1.svg" alt="logo" />
                                <h5 className='text-lg font-bold text-white'>EKMEK YAPIMI</h5>
                            </div>
                            <div className='h-10 w-10  flex items-center relative' >
                                <div className='w-full h-px bg-[#707070] absolute transform rotate-90'></div>
                                <div className='w-full h-px bg-[#707070] absolute transform rotate-180'></div>
                            </div>
                        </div>

                        <div className='flex justify-between items-center border-b border-[#707070]'>
                            <div className=' flex items-center space-x-5  w-full py-3'>
                                <img className='h-12 w-12' src="images/menu/menu-2.svg" alt="logo" />
                                <h5 className='text-lg font-bold text-white'>PASTANE</h5>
                            </div>
                            <div className='h-10 w-10  flex items-center relative' >
                                <div className='w-full h-px bg-[#707070] absolute transform rotate-90'></div>
                                <div className='w-full h-px bg-[#707070] absolute transform rotate-180'></div>
                            </div>
                        </div>
                        <div className='flex justify-between items-center border-b border-[#707070]'>
                            <div className=' flex items-center space-x-5  w-full py-3'>
                                <img className='h-12 w-12' src="images/menu/menu-3.svg" alt="logo" />
                                <h5 className='text-lg font-bold text-white'>PİZZA YAPIMI</h5>
                            </div>
                            <div className='h-10 w-10  flex items-center relative' >
                                <div className='w-full h-px bg-[#707070] absolute transform rotate-90'></div>
                                <div className='w-full h-px bg-[#707070] absolute transform rotate-180'></div>
                            </div>
                        </div>

                        <div className='flex flex-col border-b border-[#707070]'>
                            <div className='w-full flex justify-between items-center'>
                                <div className=' flex items-center space-x-5  w-full py-3'>
                                    <img className='h-12 w-12' src="images/menu/menu-4.svg" alt="logo" />
                                    <h5 className='text-lg font-bold text-white'>BUĞDAYLAR</h5>
                                </div>
                                <div className='h-10 w-10  flex items-center relative cursor-pointer ' onClick={()=>setMenuToggle(!menuToggle)} >
                                    <div className={`w-full h-px bg-[#707070] absolute transform rotate-90 transition-opacity duration-300 ${menuToggle?'opacity-0':'opacity-100'}`}></div>
                                    <div className='w-full h-px bg-[#707070] absolute '></div>
                                </div>
                            </div>
                            <div className={` overflow-hidden transition-all  duration-300 ${menuToggle?'h-40':'h-0'} left-[4.25rem] relative `}>
                                <h5 className='text-white font-light'>RUMELI</h5>
                                <h5 className='text-white font-light'>NEGEV</h5>
                                <h5 className='text-white font-light'>LUCILLA</h5>
                                <h5 className='text-white font-light'>ESPERYA</h5>
                                <h5 className='text-white font-light'>ZIYA</h5>
                            </div>
                        </div>


                    </div>

                </div>


                <div className=' flex justify-end  ' >
                    <div className='h-10 w-10 flex justify-center items-center relative cursor-pointer '  onClick={()=>{setMenuType(false)}}>
                        <div className='h-[0.12rem] w-full absolute transform rotate-45 bg-[#707070] cross-1'></div>
                        <div className='h-[0.12rem] w-full absolute transform -rotate-45 bg-[#707070] cross-2'></div>
                    </div>
                </div>

            </div>

            <img
                src="images/general/menu-banner.jpg"
                className=" h-full w-full object-cover  absolute  top-0 right-0   "
                alt="logo"
            />



            <div className=' h-full w-full object-cover  absolute  top-0 right-0 bg-[rgba(0,0,0,0.7)]'> </div>


        </div>
    )
}

export default Menu