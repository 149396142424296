import React from 'react'

const HomeProduct = () => {
  return (


      <div className="productContainer w-full bg-black  p-8 md:px-28  flex  py-20 lg:py-48 flex-col md:flex-row items-center space-y-16 lg:space-y-0 3xl:container mx-auto">
        <div className=" flex w-5/6 lg:w-1/2  ">
          <div className="flex flex-col items-center justify-center relative space-y-10">
            <div className="flex space-x-1">
              <div className="h-full w-1 bg-yellow-500 productLine1"></div>
              <div className='productText1'>
                <h6 className="text-white">Özel amaçlı</h6>
                <h6 className="text-white">Buğday Unu</h6>
              </div>
            </div>
            <img src="images/general/22.png" className="w-5/6 productPhoto1" alt="logo" />
          </div>

          <div className="flex flex-col items-center justify-center relative space-y-10">
            <div className="flex space-x-1">
              <div className="h-full w-1 bg-yellow-500 productLine2"></div>
              <div className='productText2'>
                <h6 className="text-white">Özel amaçlı</h6>
                <h6 className="text-white">Buğday Unu</h6>
              </div>
            </div>
            <img src="images/general/22.png" className="w-5/6 productPhoto2" alt="logo" />
          </div>
        </div>

        <div className=" box space-y-5  w-full flex justify-center  flex-col  md:w-1/2  md:pl-28 productContent">

          <h6 className="text-3xl text-white font-bold">Ürünler</h6>
          <p className="text-white">
            İnceoğlu Un Fabrikası’nın kuruluşu 1944 yılına dayanır. Hacı Ahmet
            İnceoğlu, Baki Abalıoğlu ve Cafer Sadık Abalıoğlu Kollektif Şirketi
            ortaklığındaki ilk un fabrikası, Eski Çal Yolu üzerinde ve 25 t/gün
            kapasiteli olarak kurulmuş ve bu fabrikayla sektörde uzun yıllar
            faaliyetini sürdürmüştür.
          </p>
          <p className="text-white">
            1965 yılından itibaren İnceoğlu Makine Ticaret ve Sanayi A.Ş olarak
            Denizli Un Fabrikaları Kollektif Şirketi’nin ortağı olmuştur. 1976
            senesinde İzmir Karayolu üzerindeki 150 t/gün kapasiteli yeni bir
            fabrika kurulmuş ve
          </p>
          <div className="h-10 w-[173px]  flex justify-center items-center  border border-white ">
            <h6 className="text-[10px] font-bold text-white">
              DAHA FAZLA İÇİN
            </h6>
          </div>

        </div>
      </div>
  )
}

export default HomeProduct