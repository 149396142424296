import React from 'react'
import { Link } from 'react-router-dom'

const Iletisim = () => {
    return (
        <div>
        <div className='h-screen bg-slate-500 w-full pt-28 relative'>
            <div className='relative h-full flex items-center '>
                <img
                    src="images/Iletisim/banner.jpg"
                    alt="logo"
                    className="  w-full h-full object-center object-cover  absolute "
                />
                <div className='w-full h-full  z-10 relative flex justify-center flex-col  md:px-16 lg:px-36'>
                    <div className='w-1/2 space-y-10'>
                    <h5 className='text-3xl font-bold ml-14'>İletişim</h5>
                    <div className='space-y-10'>


                        <div className='flex space-x-5 flex-col '>
                            <div className='flex  space-x-5'>
                                <div className=' w-10  icon1 flex justify-center '>
                                    <img
                                        src="images/footer-svg/location.svg"
                                        className="h-8 social1"
                                        alt="facebook"
                                    />
                                </div>
                                <div className='space-y-5'>
                                    <div>
                                    <a target='_blank' href='https://maps.app.goo.gl/rD3SHC2oWBss2GKLA'><h6 className='text-[#707070] icon3Text2 font-bold'>Fabrika ve Merkez Ofis</h6>
                                    <h6 className='text-[#707070] icon3Text3 '>Bozburun Mah. 7001. Sok. No:3 Merkezefendi / Denizli</h6>
                                    </a>
                                    </div>
                                    <div>
                                    <a  target='_blank' href='https://maps.app.goo.gl/rD3SHC2oWBss2GKLA'>
                                    <h6 className='text-[#707070] icon3Text2 font-bold'>Silo ve Depolama Tesisi</h6>
                                    <h6 className='text-[#707070] icon3Text3'>Bozburun Mah. 7055. Sok. No:4 Merkezefendi / Denizli</h6>
                                    </a>
                                    </div>

                                </div>
                            </div>

                        </div>


                        <div className='flex space-x-5 flex-col'>
                            <div className='flex  space-x-5'>
                                <div className='h-10 w-10  icon1 flex justify-center items-end'>
                                    <img
                                        src="images/footer-svg/phone.svg"
                                        className="h-8 social1"
                                        alt="facebook"
                                    />
                                </div>
                                <div className='flex space-x-5'>

                                <a href='tel:+902583718300'><h6 className='icon1Text2 text-[#707070]'>{`(+90) 258 371 83 00`}</h6></a>
                                    <h6 className='text-[#707070] icon2Text2'>l</h6>
                                    <a href='tel:+902583718302'><h6 className='icon1Text3 text-[#707070]'>{`(+90) 258 371 83 02`}</h6></a>
                                </div>
                            </div>

                        </div>

                        <div className='flex space-x-5 flex-col'>
                            <div className='flex  space-x-5 '>
                                <div className='h-9 w-10  icon1 flex justify-center items-end'>
                                    <img
                                        src="images/footer-svg/mail.svg"
                                        className="h-8 social1"
                                        alt="facebook"
                                    />
                                </div>
                                <div className='flex space-x-5'>

                                <a href='mailto:info@inceoglun.com.tr'><h6 className='text-[#707070] icon2Text2'>info@inceoglun.com.tr</h6></a>
                                    <h6 className='text-[#707070] icon2Text2'>l</h6>
                                    <a href='mailto:sales@inceoglun.com.tr'><h6 className='text-[#707070] icon2Text2'>sales@inceoglun.com.tr</h6></a>
                                </div>
                            </div>

                        </div>



                        <div className='flex space-x-5 flex-col'>
                            <div className='flex  space-x-5'>
                                <div className='h-10 w-10  icon1 flex justify-center items-end'>
                                    <img
                                        src="images/footer-svg/clock.svg"
                                        className="h-8 social1"
                                        alt="facebook"
                                    />
                                </div>
                                <div>

                                    <h6 className='text-[#707070] icon4Text1'>Pazartesi - Cumartesi 08:30 - 17:30</h6>
                                </div>
                            </div>
                        </div>




                    </div>

                </div>
                </div>
            </div>



        </div>
                    {/* <div className='relative h-[450px]'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d787.9452836792724!2d29.059683213492455!3d37.81859517994126!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c73fca0eeb6de5%3A0x7bf1a45728cd2a74!2zxLBuY2VvxJ9sdSBVbiBGYWJyaWthc8Sx!5e0!3m2!1sen!2str!4v1709102773081!5m2!1sen!2str" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div> */}

                    </div>
    )
}

export default Iletisim