import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup';

const CircularProgress = ({ progressPercentage, name }) => {
    const [strokeDashoffset, setStrokeDashoffset] = useState(462)
    const [lcircleIndex, setLcircleIndex] = useState(0)

    


    const radius = 60; // 50
    const stroke = 3;
   

    console.log(lcircleIndex)
    const normalizedRadius = radius - (stroke * 2);
    const circumference = normalizedRadius * 2 * Math.PI;

    useEffect(() => {
        setStrokeDashoffset(circumference - progressPercentage / 100 * circumference)
        setLcircleIndex((360 * progressPercentage) / 100)
      
      
      }, [])


  return (
    <div className="relative circular-progress-svg-container">


            <svg height={radius * 2} width={radius * 2} className=' relative' >
                <circle
                    stroke="#4A4A4A"
                    fill="transparent"
                    strokeWidth={stroke}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                <circle
                    stroke="#ffa332"
                    fill="transparent"
                    strokeWidth={stroke}
                    strokeDasharray={circumference + " " + circumference}

                    style={{
                        transition: "all 2s",
                        strokeDashoffset,
                        "backface-visibility": "hidden",
                        // "box-shadow": "0 0 1px rgba(255,255,255,0)"
                    }}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}

                />
            </svg>



            <div className={`absolute flex-1 inset-2  z-10  rounded-full flex justify-center  duration-[3000ms]`} style={{ transform: `rotate(${lcircleIndex}deg)` }}>
                <div className='h-3 w-3 bg-[#ffa332] rounded-full -top-2 relative '></div>
            </div>

            <div className='h-full w-full  absolute z-0 -top-2  flex justify-center items-center flex-col space-y-1'>
                <div className='flex items-center'>
                    <h3 className=' text-white font-extrabold '>
                        <CountUp end={progressPercentage} duration={2}className='text-xs'/>
                    </h3>
                    <h6 className=' text-white font-extrabold text-xs '>%</h6>
                </div>
                <h6 className=' text-white font-bold text-xs'>{name}</h6>
            </div>



        </div>
  )
}

export default CircularProgress






