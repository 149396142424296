import { useGSAP } from '@gsap/react'
import { Lenis } from '@studio-freight/react-lenis'
import gsap from 'gsap'
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { useLocation } from 'react-router-dom'






const Home41 = () => {
const section1 = useRef()
const section2 = useRef()

let location = useLocation();

useEffect(() => {
    
    if(location.hash === '#section1'){
        window.scrollTo(0,section1.current.offsetTop)
    }

    if(location.hash === '#section2'){
        window.scrollTo(0,section2.current.offsetTop)
    }

}, [location])

  

    return (
        <div >

            <div className='w-full  bg-black space-y-16  pt-20 md:pt-28'>


                <div className='w-full h-[947px] overflow-hidden'>
                    <img
                        src="images/general/XV4A0756.jpg"
                        alt="logo"
                        className=" w-full h-full object-center object-cover  "
                    />
                </div>

                <div className='w-full flex flex-col items-center space-y-14 px-8   md:px-40 lg:px-40 pb-20'>
                    <h3 className='text-white text-[2.75rem]'>Buğday</h3>
                    <h3 className='text-white text-[2.75rem]'>Canlı Bir Malzemeyi Seçip Işliyoruz</h3>
                    <div className='space-y-5'>
                        <h6 className='text-white text-sm text-center' >Genel olarak en iyi buğday diye bir şey yoktur, bu nedenle bizim için en önemli beceri, farklı buğday türlerini seçme, bunları işleme ve belirli ürün türleri için özel unlar ve formülasyonlar elde edecek şekilde karıştırma yeteneğidir.</h6>
                        <h6 className='text-white text-sm text-center' >

                            Farklı coğrafyalarda, farklı yetiştirme teknikleriyle ve mevsimlerin nasıl geliştiğine ilişkin ek değişkenlerle yetiştirilen buğdaylar arasındaki protein farkı, nihai ürünün kalitesi açısından buğday seçme çalışmalarımızı özellikle karmaşık ve stratejik hale getiriyor.
                        </h6>
                    </div>
                </div>
                <div ref={section1} className='player-wrapper'>
                    <ReactPlayer url='videos/1.mp4' playing loop className='react-player'
                        width='100%'
                        height='100%'
                    />
                </div>


                {/* <Player autoPlay={true} fluid playsInline  >
                        <source src="videos/1.mp4" />
                </Player> */}

                {/* <div className='w-full h-[947px] overflow-hidden'>
                    <img
                        src="images/general/Sequence1.png"
                        alt="logo"
                        className=" w-full h-full object-center object-cover  "
                    />
                </div> */}

                <div className='w-full flex flex-col items-center space-y-14 px-8   md:px-40 lg:px-40'>
                    <h3 className='text-white text-[2.75rem]'>Her Un Için Doğru Buğday</h3>

                    <div className='space-y-5'>
                        <h6 className='text-white text-sm text-center' >Fırıncılık profesyonelleri için geliştirilen teknik bir ürün için, her zaman nasıl kullanılacaklarına bağlı olarak belirli malzemeleri seçtik.

                            Üretim tesisimiz, en iyi buğdayları sağlama konusunda ayrıcalıklı bir konumdadır: hasatın gidişatına bağlı olarak en kaliteli buğdayı seçmemize olanak sağlayan bir lojistik avantaj.
                            Buğdayın özellikleri mevsimlerin nasıl geçtiğine ve aynı zamanda yetiştiği toprağa da bağlıdır.
                        </h6>
                        <h6 className='text-white text-sm text-center' >

                            İklime ve toprağın özelliklerine bağlı verime dayalı ve doğanın döngülerine saygı gösteren seçim, protein içeriği ve genel olarak besin değerleri açısından tutarlı, yüksek performanslı kalitede unları garanti eder.
                        </h6>
                    </div>
                </div>



                <div className='w-full h-[947px] overflow-hidden pb-40'>
                    <img
                        src="images/general/menu.jpg"
                        alt="logo"
                        className=" w-full h-full object-center object-cover  "
                    />
                </div>



                <div ref={section2} tabIndex={2} className='player-wrapper'>
                    <ReactPlayer url="videos/2.mp4" playing loop className='react-player' previewTabIndex={2}
                        width='100%'
                        height='100%'
                    />
                </div>
                {/* 
                <Player autoPlay  fluid playsInline>
                        <source src="videos/2.mp4" />
                </Player> */}




            </div>

        </div>
    )
}

export default Home41