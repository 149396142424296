import React, { useRef } from 'react'


const Slider = () => {


  return (
    <div class="xSlider" >

      <section class="panel  " className="bg-black w-full md:h-[calc(100vh-7rem)] h-[calc(100vh-10rem)] md:mt-[7rem] mt-[5rem] panel  relative overflow-hidden" >
        <img
          src="images/slider/slider1.jpg"
          className="w-full object-cover h-full"
          alt="slider-1"
        />
        <div className='h-full w-full  absolute px-8 md:px-16 lg:px-36 slider1content redd'>
          <div className='h-full w-full  py-20'>
            <div className=''>
              <div className="h-full w-full   z-20 relative   flex  ">
                <div className=" w-full md:w-1/2 space-y-5 ">
                  <h6 className="text-3xl  font-bold">LOREM IPSUM</h6>
                  <p className="">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat
                  </p>

                  <div className="h-10 w-[173px]  flex justify-center items-center  border border-black ">
                    <h6 className="text-[10px] font-bold ">Devamı İçin</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="panel orange" className=" w-full md:h-[calc(100vh-7rem)] h-[calc(100vh-10rem)] md:mt-[7rem] mt-[5rem] panel text-black overflow-hidden overflow-hidden">
        <img
          src="images/slider/slider2.jpg"
          className="w-full object-cover h-full"
          alt="slider-2"
        />
        <div className='h-full w-full  absolute px-8 md:px-16 lg:px-36'>
          <div className='h-full w-full  py-20'>
            <div className=''>
              <div className="h-full w-full   z-20 relative   flex  ">
                <div className=" w-full md:w-1/2 space-y-5 ">
                  <h6 className="text-3xl  font-bold">LOREM IPSUM</h6>
                  <p className="">
                     Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat
                  </p>

                  <div className="h-10 w-[173px]  flex justify-center items-center  border border-black ">
                    <h6 className="text-[10px] font-bold ">Devamı İçin</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="panel purple" className="bg-black w-full md:h-[calc(100vh-7rem)] h-[calc(100vh-10rem)] md:mt-[7rem] mt-[5rem] panel overflow-hidden">
        <img
          src="images/slider/slider1.jpg"
          className="w-full object-cover h-full"
          alt="slider-3"
        />
        <div className='h-full w-full  absolute px-8 md:px-16 lg:px-36'>
          <div className='h-full w-full  py-20'>
            <div className=''>
              <div className="h-full w-full   z-20 relative   flex  ">
                <div className=" w-full md:w-1/2 space-y-5 ">
                  <h6 className="text-3xl  font-bold">LOREM IPSUM</h6>
                  <p className="">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat
                  </p>

                  <div className="h-10 w-[173px]  flex justify-center items-center  border border-black ">
                    <h6 className="text-[10px] font-bold ">Devamı İçin</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="panel green" className="bg-white w-full md:h-[calc(100vh-7rem)] h-[calc(100vh-10rem)] md:mt-[7rem] mt-[5rem] panel text-black overflow-hidden">
        <img
          src="images/slider/slider2.jpg"
          className="w-full object-cover h-full"
          alt="slider-1"
        />
        <div className='h-full w-full  absolute px-8 md:px-16 lg:px-36'>
          <div className='h-full w-full  py-20'>
            <div className=''>
              <div className="h-full w-full   z-20 relative   flex  ">
                <div className=" w-full md:w-1/2 space-y-5 ">
                  <h6 className="text-3xl  font-bold">LOREM IPSUM</h6>
                  <p className="">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat
                  </p>
 
                  <div className="h-10 w-[173px]  flex justify-center items-center  border border-black ">
                    <h6 className="text-[10px] font-bold ">DAHA FAZLA İÇİN</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="panel gray" className="border-green-600 w-full md:h-[calc(100vh-7rem)] h-[calc(100vh-10rem)] md:mt-[7rem] mt-[5rem] ">
        5
      </section>
    </div>
  )
}

export default Slider