import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';



// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const History = () => {

  const onInit = () => {
    console.log('lightGallery has been initialized');
};

  useGSAP(
    () => {
      
      gsap.from(".history", {

        
        duration: 2, 
        opacity:0

      });

      gsap.from(".history-content-container", {

        x:50,
        duration: 2, 
        opacity:0

      });

      
    })
  return (
    <div className='relative  history' >
      
      <div className='h-auto lg:h-screen pt-20 md:pt-28 relative overflow-hidden'>
      <img
              src="images/general/bina.jpg"
              alt="logo"
              className="  w-full h-full object-center object-cover  absolute"
      />


      <div className='h-full w-full  z-20 relative px-8 md:px-16 lg:px-36 py-10 history-content-container'>
        <div className='w-full lg:w-[40%] h-full bg-[rgba(255,255,255,0.8)] p-10 space-y-5'>
          <h5 className='font-bold text-3xl'>Tarihçe</h5>
          <p className='text-sm'>İnceoğlu Un Fabrikası’nın kuruluşu 1944 yılına dayanır. Hacı Ahmet İnceoğlu, Baki Abalıoğlu ve Cafer Sadık Abalıoğlu Kollektif Şirketi ortaklığındaki ilk un fabrikası, Eski Çal Yolu üzerinde ve 25 t/gün kapasiteli olarak kurulmuş ve bu fabrikayla sektörde uzun yıllar faaliyetini sürdürmüştür.</p>
          <p className='text-sm'>1966 yılından itibaren İnceoğlu Makine Ticaret ve Sanayi A.Ş olarak Denizli Un Fabrikaları Kollektif Şirketi’nin ortağı olmuştur. 1976 senesinde İzmir Karayolu üzerindeki 150 t/gün kapasiteli  yeni bir fabrika kurulmuş ve modern üretim geçmişten gelen deneyimle harmanlanmıştır. İnceoğlu Un Fabrikası sağlam bir temele oturmuş sektörel faaliyetlerini, teknik açıdan da devamlı geliştirerek, kalite çizgisini hep zirvede tutmayı amaçlamıştır.</p>
          <p className='text-sm'>2007 yılına gelindiğinde, yeniden yapılanma sonucu, müşterilerine  üretimini kendi isminden gelen “İNCEOĞLUN” markasıyla sunmaya başladı. Revizyonlarla 300t/gün öğütme kapasitesine ulaşan İnceoğlu Un Fabrikası, bugün  modern gıda işletmeciliğinin uluslararası standartlarında üretimini gerçekleştirmektedir. </p>
        </div>
      </div>


      </div>
      <div className='px-8 md:px-16 lg:px-36 mt-10'>
      <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
              
            >
                <a style={{marginRight:'2.5rem'}}  href="images/history/1.jpg">
                    <img alt="img1" src="images/history/1.jpg" />
                </a>
                <a href="images/history/2.jpg">
                    <img alt="img2" src="images/history/2.jpg" />
                </a>
            
            </LightGallery>
      </div>
      
    </div>
  )
}

export default History