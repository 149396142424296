import React, { useRef } from 'react'
import Slider from '../components/Slider'
import HomeBanner from '../components/HomeBanner'
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import HomeProduct from '../components/HomeProduct';

const Home = () => {
  const Container = useRef();

  useGSAP(
    () => {
      
      gsap.to(".c", {

        
        duration: 2, 
        opacity:1

      });

      
        

      const sections = gsap.utils.toArray(".panel");

      gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ".xSlider",
          pin: true,
          scrub: 1,
          snap: 1 / (sections.length - 1),
          start: "top top",
          end: "bottom",

        },
      });



      // gsap.to(".slider1content", {
      //   xPercent:50,
    
      //   ease: "none",
      //   scrollTrigger: {
      //     trigger: ".redd",
          

      //     start: "top ",
      //     end: "top  ",

      //     markers: true,

      //       id: "denemeaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
      //   },
      // })

      
     

      gsap.to(".box1", {

        start: "bottom bottom",
        end: "top top",
        scrollTrigger: {
            trigger:'.box',
            

        },
        duration: 2, 
        opacity:1

      });

      gsap.to(".box2", {

        start: "bottom bottom",
        end: "top top",
        scrollTrigger: {
            trigger:'.box',
            

        },
        duration: 3, 
        opacity:1

      });

      gsap.to(".box3", {

        start: "bottom bottom",
        end: "top top",
        scrollTrigger: {
            trigger:'.box',
            

        },
        duration: 4, 
        opacity:1

      });

      

      // gsap.from(".hello1", {

      //   start: "top 200vh",
      //   end: "bottom ",
      //   scrollTrigger: {
      //       trigger:'hello',
      //       duration: 2, 
      //       rotation: 360,
      //       markers: true,

      //       id: "scrub1"
      //   }

      // });


      gsap.from(".banner-wheat-1", {
        yPercent: -10,
        ease: "none",
        scrollTrigger: {
          trigger: ".homeBannerContainer",
          // start: "top top", // the default values
          // end: "bottom top",

          scrub: 2
        }, 
      });
      gsap.from(".banner-wheat-2", {
        yPercent: -60,
        ease: "none",
        scrollTrigger: {
          trigger: ".homeBannerContainer",
          // start: "top bottom", // the default values
          // end: "bottom top",
          scrub: 2
        }, 
      });
      gsap.from(".banner-wheat-3", {
        yPercent: 20,
        ease: "none",
        scrollTrigger: {
          trigger: ".homeBannerContainer",
          // start: "top bottom", // the default values
          // end: "bottom top",
          scrub: 2
        }, 
      });
      gsap.from(".banner-wheat-4", {
        yPercent: 40,
        ease: "none",
        scrollTrigger: {
          trigger: ".homeBannerContainer",
          // start: "top bottom", // the default values
          // end: "bottom top",
          scrub: 2
        }, 
      });
      gsap.from(".banner-wheat-5", {
        yPercent: -20,
        ease: "none",
        scrollTrigger: {
          trigger: ".homeBannerContainer",
          // start: "top bottom", // the default values
          // end: "bottom top",
          scrub: 2
        }, 
      });



      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".aboutuscontainer",
          start: "top top",
          end: "+=1000",
          scrub: 1,
          pin: true,

        }
      });
      

      tl.from(".aboutusimg", { scale:2 ,opacity:0,translateX:'100%',duration:2})
      tl.from(".aboutuslogo", {  opacity:0,duration:2})
      tl.from(".aboutuscontent", {  opacity:0 ,translateX:100,duration:2 })

      
      




      gsap.to(".truckImg", {
        y:200,
        
        ease: "none",
        
        scrollTrigger: {
          trigger: ".truckContainer",
          
          // start: "top bottom", // the default values
          // end: "bottom top",
          scrub: 1
        }, 
      });



      const homeProductTl = gsap.timeline({
        scrollTrigger: {
          trigger: ".productContainer",
          start: "top top",
          end: "+=2000",
          scrub: 1,
          pin: true,

          
        }
      });

      homeProductTl.from(".productLine1", { opacity:0,scale:2})
      homeProductTl.from(".productLine2", { opacity:0})
      homeProductTl.from(".productPhoto1", { opacity:0 ,translateX:100,duration:2,scale:1.5})

      homeProductTl.from(".productText1", { opacity:0})
      homeProductTl.from(".productPhoto2", {  opacity:0 ,translateX:100,duration:2,scale:1.5})
      homeProductTl.from(".productText2", { opacity:0 ,translateX:100,duration:2})
      homeProductTl.from(".productContent", {  opacity:0 ,translateX:100,duration:2 })




      const wheatTl = gsap.timeline({
        scrollTrigger: {
          trigger: ".wheatsContainer",
          start: "top top",
          end: "+=500",
          scrub: 1,
          pin: true,

          
        }
      });
      

      wheatTl.from(".wheat-1", { scale:1 ,opacity:0,duration:2,translateY:-200 ,yPercent:100,})
      wheatTl.from(".wheat-3", { scale:1 ,opacity:0,translateX:'100%',duration:2,translateY:-200})
      wheatTl.from(".wheat-5", { scale:1 ,opacity:0,translateX:'100%',duration:2,translateY:-200})
      wheatTl.from(".wheat-10", { scale:1 ,opacity:0,translateX:'100%',duration:2,translateY:-200})
      wheatTl.from(".wheat-8", { scale:1 ,opacity:0,translateX:'100%',duration:2,translateY:-200})
      wheatTl.from(".wheat-4", { scale:1 ,opacity:0,translateX:'100%',duration:2,translateY:-200})
      wheatTl.from(".wheat-2", { scale:1 ,opacity:0,translateX:'100%',duration:2,translateY:-200})
      wheatTl.from(".wheat-6", { scale:1 ,opacity:0,translateX:'100%',duration:2,translateY:-200})
      wheatTl.from(".wheat-7", { scale:1 ,opacity:0,translateX:'100%',duration:2,translateY:-200})
      wheatTl.from(".wheat-9", { scale:1 ,opacity:0,translateX:'100%',duration:2,translateY:-200})
      wheatTl.from(".wheat-11", { scale:1 ,opacity:0,translateX:'100%',duration:2,translateY:-200})


      gsap.from(".arge-img", {
        opacity:0,
        ease: "none",
        scrollTrigger: {
          trigger: ".arge-container",
          // start: "top bottom", // the default values
          // end: "bottom top",
          
        }, 
      });
      gsap.from(".arge-content", {
        opacity:0 ,translateX:100,duration:1 ,
        ease: "none",
        scrollTrigger: {
          trigger: ".arge-container",
          // start: "top bottom", // the default values
          // end: "bottom top",
          
        }, 
      });







      gsap.utils.toArray(".animCounter").forEach(box => {
        var tler =  gsap.from(box, {
            textContent: "0",
            duration: 3,
            ease: "power1.inOut",
            modifiers: {
                textContent: value => formatNumber(value, 0) + "+"
            },
            scrollTrigger: {
                trigger: box,
                start: "100px 80%",
                end: "+=100",
                toggleActions: "play none none reverse",
                end: "top 100%",
                once:true
      
            }
        }); })
      
      
      function formatNumber(value, decimals) {
        let s = (+value).toLocaleString('en-US').split(".");
        return decimals ? s[0] + "." + ((s[1] || "") + "00000000").substr(0, decimals) : s[0];
      }
      






    },


    
    { scope: Container }

    
  );
  return (
    <div className=' ' ref={Container}>
      <div className='c opacity-0'>
      <Slider />

      <div className="box my-20 w-full  space-y-10  md:space-y-0 grid grid-cols-1 md:grid-cols-3  px-8 md:px-16 lg:px-36 py-12 md:gap-10 3xl:container mx-auto">
        <div className="box1 grid-cols-4 space-y-5   opacity-0">
          <h6 className="text-3xl text-white font-bold">Misyon&Vizyon</h6>
          <p className="text-white yourElement">
            Misyonumuz : Kaliteli un ve un türevi ürünlerin üretimini sağlayarak
            insanların güvenli gıdaya ulaşmasını sağlamaktır.
          </p>
          <p className="text-white">
            Vizyonumuz : “Gıdaya saygı, çevreye duyarlı, uluslararası bir gıda
            tedarikçisi olmak”
          </p>
          <div className="h-10 w-[173px]  flex justify-center items-center  border border-white">
            <h6 className="text-[10px] font-bold text-white">
              DAHA FAZLA İÇİN
            </h6>
          </div>
        </div>
        <div className="box2 grid-cols-4 space-y-5 opacity-0">
          <h6 className="text-3xl text-white font-bold">Misyon&Vizyon</h6>
          <p className="text-white">
            Misyonumuz : Kaliteli un ve un türevi ürünlerin üretimini sağlayarak
            insanların güvenli gıdaya ulaşmasını sağlamaktır.
          </p>
          <p className="text-white">
            Vizyonumuz : “Gıdaya saygı, çevreye duyarlı, uluslararası bir gıda
            tedarikçisi olmak”
          </p>
          <div className="h-10 w-[173px]  flex justify-center items-center  border border-white">
            <h6 className="text-[10px] font-bold text-white">
              DAHA FAZLA İÇİN
            </h6>
          </div>
        </div>
        <div className="box3 grid-cols-4 space-y-5  opacity-0">
          <h6 className="text-3xl text-white font-bold">Misyon&Vizyon</h6>
          <p className="text-white">
            Misyonumuz : Kaliteli un ve un türevi ürünlerin üretimini sağlayarak
            insanların güvenli gıdaya ulaşmasını sağlamaktır.
          </p>
          <p className="text-white">
            Vizyonumuz : “Gıdaya saygı, çevreye duyarlı, uluslararası bir gıda
            tedarikçisi olmak”
          </p>
          <div className="h-10 w-[173px]  flex justify-center items-center  border border-white">
            <h6 className="text-[10px] font-bold text-white">
              DAHA FAZLA İÇİN
            </h6>
          </div>
        </div>
      </div>





      <HomeBanner />




    




      {/* xx */}
      <div className=" aboutuscontainer w-full  relative  px-8 md:px-16 lg:px-36  bg-black flex justify-between items-center py-48 flex-col space-y-10 md:flex-row md:space-y-0 md:space-x-10 3xl:container mx-auto "
      >
        <div className=" aboutusimg w-full  md:w-1/2 relative ">
          <div className='w-full relative overflow-hidden'>
          <img
            src="images/general/dsdsdsd.jpg"
            className=" min-w-[834px] min-h-[348px] object-cover"
            alt="logo"
          />
          </div>
          <div className='  absolute z-20 -bottom-7 -right-7 md:-bottom-16 md:-right-16 aboutuslogo'>
           <img src="images/logos/logo2.png" class="h-14 md:h-32 " alt="logo"/>

          </div>
        </div>
        <div

          className="aboutuscontent  space-y-5  w-full flex justify-center  flex-col  md:w-1/2      md:pl-20 ">
          <h6 className="text-3xl text-white font-bold">Hakkımızda</h6>
          <p className="text-white  ">
            İnceoğlu Un Fabrikası’nın kuruluşu 1944 yılına dayanır. Hacı Ahmet
            İnceoğlu, Baki Abalıoğlu ve Cafer Sadık Abalıoğlu Kollektif Şirketi
            ortaklığındaki ilk un fabrikası, Eski Çal Yolu üzerinde ve 25 t/gün
            kapasiteli olarak kurulmuş ve bu fabrikayla sektörde uzun yıllar
            faaliyetini sürdürmüştür.
          </p>
          <p className="text-white">
            1965 yılından itibaren İnceoğlu Makine Ticaret ve Sanayi A.Ş olarak
            Denizli Un Fabrikaları Kollektif Şirketi’nin ortağı olmuştur. 1976
            senesinde İzmir Karayolu üzerindeki 150 t/gün kapasiteli yeni bir
            fabrika kurulmuş ve
          </p>
          <div className="h-10 w-[173px]  flex justify-center items-center  border border-white">
            <h6 className="text-[10px] font-bold text-white">
              DAHA FAZLA İÇİN
            </h6>
          </div>
        </div>
      </div>


      {/* TRUCK */}

      <div className="w-full h-[35rem] 3xl:h-[50vh]  overflow-hidden relative truckContainer bg-red-800 ">
        <img
          src="images/general/truck.jpg"
          className="  object-cover   absolute  truckImg  -top-[60%] object-center w-full 3xl:-top-[100%] "
          alt="logo"
        />

        <div className="h-full w-full   z-20 relative   flex justify-center items-center md:justify-end ">
          <div className=" w-full md:w-1/2 space-y-5 px-8 md:px-16 lg:px-32 ">
            <h6 className="text-3xl  font-bold">Misyon&Vizyon</h6>
            <p className="">
              Misyonumuz : Kaliteli un ve un türevi ürünlerin üretimini
              sağlayarak insanların güvenli gıdaya ulaşmasını sağlamaktır.
            </p>
            <p className="">
              Vizyonumuz : “Gıdaya saygı, çevreye duyarlı, uluslararası bir gıda
              tedarikçisi olmak”
            </p>
            <div className="h-10 w-[173px]  flex justify-center items-center  border border-black ">
              <h6 className="text-[10px] font-bold ">DAHA FAZLA İÇİN</h6>
            </div>
          </div>
        </div>
      </div>

    <HomeProduct/>

      <div className='h-screen flex justify-center items-center wheatsContainer'>
      <div className='w-full h-80     flex justify-center mb-20 '>
        <div className='h-full w-full  relative'>
          <img
            src="images/wheat-2/wheat-1.png"
            alt="logo"
            className=" object-contain max-w-[44px] absolute max-h-[63px] left-[16.3%] top-[30.7%] wheat-1"
          />

          <img
            src="images/wheat-2/wheat-2.png"
            alt="logo"
            className=" object-contain max-w-[44px] absolute max-h-[63px] left-[21.42%] top-[51.6%] wheat-2"
          />

          <img
            src="images/wheat-2/wheat-3.png"
            alt="logo"
            className=" object-contain max-w-[82px] absolute max-h-[111px] left-[23.5%] top-[45.9%] wheat-3"
          />

          <img
            src="images/wheat-2/wheat-4.png"
            alt="logo"
            className=" object-contain max-w-[44px] absolute max-h-[63px] left-[27.8%] top-[45.9%] wheat-4"
          />

          <img
            src="images/wheat-2/wheat-5.png"
            alt="logo"
            className=" object-contain max-w-[119px] absolute max-h-[74px] left-[39.4%] top-[52.6%] wheat-5"
          />

          <img
            src="images/wheat-2/wheat-6.png"
            alt="logo"
            className=" object-contain max-w-[116px] absolute max-h-[119px] left-[60.9%] top-[59.2%] wheat-6"
          />

          <img
            src="images/wheat-2/wheat-7.png"
            alt="logo"
            className=" object-contain max-w-[82px] absolute max-h-[111px] left-[66.5%] top-[45.9%] wheat-7"
          />

          <img
            src="images/wheat-2/wheat-8.png"
            alt="logo"
            className=" object-contain max-w-[119px] absolute max-h-[74px] left-[69.8%] top-[35%] wheat-8"
          />

          <img
            src="images/wheat-2/wheat-9.png"
            alt="logo"
            className=" object-contain max-w-[49px] absolute max-h-[66px] left-[73.4%] top-[63.8%] wheat-9"
          />

          <img
            src="images/wheat-2/wheat-10.png"
            alt="logo"
            className=" object-contain max-w-[49px] absolute max-h-[66px] left-[77.6%] top-[33.3%] wheat-10"
          />

          <img
            src="images/wheat-2/wheat-11.png"
            alt="logo"
            className=" object-contain max-w-[25px] absolute max-h-[37px] left-[77.6%] top-[45.9%] wheat-11"
          />

        </div>
      </div>
      </div>

      

      <section className="w-full   md:h-[550px]  flex bg-[#ECE2D1] flex-col md:flex-row arge-container">
        <div className="w-full md:w-1/2 h-72 md:h-full relative flex float-end overflow-hidden arge-img">
          <img
            src="images/general/img-9.png"
            className="  md:h-full  md:right-0  transform  object-cover object-right not-sr-only  "
            // className="min-w-[76.188rem] min-h-[43.375rem] h-[43.375rem] w-[76.188rem] "
            alt="logo"
          />
        </div>

        <div className="w-full md:w-1/2 h-full flex items-center arge-content">
          <div className="space-y-5  md:pr-36 md:pl-28 p-8">
            <div className="flex space-x-10">
              <h6 className="text-3xl font-bold">AR-GE</h6>
              <img
                src="images/general/3.png"
                className="w-[63.07px] h-[52.9px] "
                // className="min-w-[76.188rem] min-h-[43.375rem] h-[43.375rem] w-[76.188rem] "
                alt="logo"
              />
            </div>

            <p className="">
              İnceoğlu Un Fabrikası’nın kuruluşu 1944 yılına dayanır. Hacı Ahmet
              İnceoğlu, Baki Abalıoğlu ve Cafer Sadık Abalıoğlu Kollektif
              Şirketi ortaklığındaki ilk un fabrikası, Eski Çal Yolu üzerinde ve
              25 t/gün kapasiteli olarak kurulmuş ve bu fabrikayla sektörde uzun
              yıllar faaliyetini sürdürmüştür.
            </p>
            <p className="">
              1965 yılından itibaren İnceoğlu Makine Ticaret ve Sanayi A.Ş
              olarak Denizli Un Fabrikaları Kollektif Şirketi’nin ortağı
              olmuştur. 1976 senesinde İzmir Karayolu üzerindeki 150 t/gün
              kapasiteli yeni bir fabrika kurulmuş ve
            </p>
            <div className="h-10 w-[173px]  flex justify-center items-center  border border-black">
              <h6 className="text-[10px] font-bold ">DAHA FAZLA İÇİN</h6>
            </div>
          </div>
        </div>
      </section>

      {/* -----------------AR-GE-----END-------------------------------------------- */}

      
      <div className=" bg-black w-full  pt-32 pb-16 flex items-center justify-center">
        

        <div className=" grid grid-cols-1  md:grid-cols-7  gap-5 md:gap-10">
          <div className="  flex flex-col items-center space-y-2 ">
            <h5 className="text-7xl md:text-6xl font-bold  text-white animCounter">20</h5>
            <h5 className="text-1xl md:text-[0.4rem]  md:text-sm  text-white">
              Business Year
            </h5>
          </div>

          <div className="  flex flex-col items-center h-full ">
            <div className=" w-full h-full hidden md:flex  items-center">
              <div className="h-px w-full bg-white  "></div>
            </div>
            <h5 className="text-[0.4rem]  md:text-sm hidden md:block">Business Year</h5>
            <div className="w-1 h-20 flex justify-center md:hidden">
              <div className="h-full w-px bg-white"></div>
            </div>
          </div>

          <div className="  flex flex-col items-center space-y-2 ">
            <h5 className="text-7xl md:text-6xl font-bold  text-white animCounter">80</h5>
            <h5 className="text-1xl md:text-[0.4rem]  md:text-sm  text-white">
              Business Year
            </h5>
          </div>

          <div className="  flex flex-col items-center h-full ">
            <div className=" w-full h-full hidden md:flex  items-center">
              <div className="h-px w-full bg-white  "></div>
            </div>
            <h5 className="text-[0.4rem]  md:text-sm hidden md:block">Business Year</h5>
            <div className="w-1 h-20 flex justify-center md:hidden">
              <div className="h-full w-px bg-white"></div>
            </div>
          </div>

          <div className="  flex flex-col items-center space-y-2 ">
            <h5 className="text-7xl md:text-6xl font-bold  text-white animCounter">75</h5>
            <h5 className="text-1xl md:text-[0.4rem]  md:text-sm  text-white">
              Business Year
            </h5>
          </div>

          <div className="  flex flex-col items-center h-full ">
            <div className=" w-full h-full hidden md:flex  items-center">
              <div className="h-px w-full bg-white  "></div>
            </div>
            <h5 className="text-[0.4rem]  md:text-sm hidden md:block">Business Year</h5>
            <div className="w-1 h-20 flex justify-center md:hidden">
              <div className="h-full w-px bg-white"></div>
            </div>
          </div>


          <div className="  flex flex-col items-center space-y-2 ">
            <h5 className="text-7xl md:text-6xl font-bold  text-white animCounter">40</h5>
            <h5 className="text-1xl md:text-[0.4rem]  md:text-sm  text-white">
              Business Year
            </h5>
          </div>




        </div>
        
      </div>
      <div className='h-16 w-full  flex justify-center items-center'>

      <img
        src="images/icons/top-arrow.svg"
        className=""
        alt="top-arrow"
      />

    </div>
    </div>
    </div>
  )
}

export default Home