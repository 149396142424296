import React, { useEffect, useLayoutEffect, useState } from 'react'


const InsanKaynakları = ({ setHeaderTransparent }) => {

    useLayoutEffect(() => {
        setHeaderTransparent(true)

        return(()=>setHeaderTransparent(false))
    }, [])

    const [check1, setCheck1] = useState(false)
    const [check2, setCheck2] = useState(false)
    return (
        <div className='relative ' >

            <div className=' lg:h-screen  relative overflow-hidden'>
                <img
                    src="images/general/jobtitle.jpg"
                    alt="logo"
                    className="  w-full h-full object-center object-cover  absolute"
                />
                <div className=' h-full w-full object-cover  absolute  top-0 right-0 bg-[rgba(0,0,0,0.5)]'> </div>


                <div className='h-full w-full  z-20 relative pt-20 md:pt-28 px-8 md:px-16 lg:px-36  flex flex-col space-y-8'>
                    <div className='text-white font-bold text-4xl'>İş Başvurusu</div>
                    <div className='w-full   grid grid-cols-3 gap-10'>
                        <div className='h-full w-full  space-y-3'>
                            <div className='space-y-3'>
                                <h5 className='text-white'>Ad*</h5>
                                <input className='w-full h-[2.50rem]' />

                            </div>
                            <div className='space-y-3'>
                                <h5 className='text-white'>Soyad*</h5>
                                <input className='w-full h-[2.50rem]' />

                            </div>
                            <div className='space-y-3'>
                                <h5 className='text-white'>Cep Telefonu*</h5>
                                <input className='w-full h-[2.50rem]' />

                            </div>
                            <div className='space-y-3'>
                                <h5 className='text-white'>E posta**</h5>
                                <input className='w-full h-[2.50rem]' />

                            </div>
                            <div className='space-y-3'>
                                <h5 className='text-white'>Firma Ünvanı</h5>
                                <input className='w-full h-[2.50rem]' />

                            </div>
                        </div>
                        <div className='h-full w-full  space-y-3'>
                            <div className='space-y-3'>
                                <h5 className='text-white'>Konu*</h5>

                                <input className='w-full h-[2.50rem]' />


                            </div>
                            <div className='space-y-3'>
                                <h5 className='text-white'>Mesaj*</h5>
                                <input className='w-full h-[9.75rem]' />

                            </div>


                            <div className='flex space-x-3'>
                                <div className='max-h-[15px]  min-w-[15px] relative bg-white flex justify-center items-center  top-1.5 '>
                                    <div className={`h-[9px] w-[9px] bg-[#EFAD02] ${check1 ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}></div>
                                    <input type='checkbox' onClick={() => setCheck1(!check1)} className='h-full w-full absolute z-0 opacity-0 ' />

                                </div>
                                <h5 className=' leading-0 underline text-white'>Kişisel Verilerin Korunması Kanunu Bilgilendirmesini okudum.</h5>
                            </div>


                            <div className='flex space-x-3'>
                                <div className=' max-h-[15px]  min-w-[15px] relative bg-white flex justify-center items-center top-1.5'>
                                    <div className={`h-[9px] w-[9px] bg-[#EFAD02] ${check2 ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}></div>
                                    <input type='checkbox' onClick={() => setCheck2(!check2)} className='h-full w-full absolute z-0 opacity-0' />

                                </div>
                                <h5 className=' leading-0 text-white '>Kişisel verilerimin ve özel nitelikli kişisel verilerimin işlenmesine, saklanmasına ve 3. kişilere aktarılmasına muvafakat ederim. Garanti Filo ve iştiraklerinin kampanya, duyuru, ürün ve </h5>
                            </div>


                        </div>
                        <div className='h-full space-y-8'>
                            <div className='space-y-4'>
                                <h5 className='text-white font-bold text-sm'>İş Tanımı</h5>
                                <h6 className='text-white text-sm'>İnceoğlu Un Fabrikası’nda görevlendirilmek üzere İmalat Elemanı işe alımı yapılacaktır.</h6>
                            </div>
                            <div className='space-y-4'>
                                <h5 className='text-white font-bold text-sm'>Aranan Nitelikler</h5>
                                <div>
                                <h6 className='text-white text-sm'>-Takım çalışmasına yatkın</h6>
                                <h6 className='text-white text-sm'>-Mesaili sistemde çalışabilecek</h6>
                                <h6 className='text-white text-sm'>-Denizli’de ikamet eden</h6>
                                </div>
                            </div>
                            <div >
                                <h5 className='text-white text-sm'><span className='font-bold'>Pozisyon:</span>  İmalat Elemanı</h5>
                                <h5 className='text-white text-sm'><span className='font-bold'>Sektör:</span> Gıda</h5>
                                <h5 className='text-white text-sm'><span className='font-bold'>Kategori:</span> Üretim/Kalite</h5>
                                <h5 className='text-white text-sm'><span className='font-bold'>Çalışma Şekli:</span> Tam zamanlı</h5>
                                <h5 className='text-white text-sm'><span className='font-bold'>Pozisyon Seviyesi:</span> Üretim Personeli</h5>
                                <h5 className='text-white text-sm'><span className='font-bold'>Çalışma Yeri:</span> Denizli Merkezefendi</h5>
                            </div>
                        </div>
                    </div>
                    <div className='h-12 w-full  grid grid-cols-3  gap-10'>
                        <div className='h-full text-lg w-32 col-start-2 leading-[3rem] font-bold bg-[#EFAD02] text-center text-white'>GÖNDER</div>
                    </div>



                </div>
                


            </div>

        </div>
    )
}

export default InsanKaynakları