import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import React, { useRef } from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  const footerContainer = useRef()




  // useGSAP(
  //   ()=>{


  //     const footerTl = gsap.timeline({
  //       scrollTrigger: {
  //         trigger: ".footerContainer",
  //         start: "top center",
  //         end:"top center",
          
  //         id: "scrub22222222",
  //         scrub: 2,
          
  //         markers: true
  //       }
  //     });
  //     footerTl.from(".icon1", { opacity:0})
  //     footerTl.from(".icon1Text1", { opacity:0})
  //     footerTl.from(".icon1Text2", {  opacity:0})
  //     footerTl.from(".icon1Text3", {  opacity:0 })

  //     footerTl.from(".icon2", { opacity:0})
  //     footerTl.from(".icon2Text1", { opacity:0})
  //     footerTl.from(".icon2Text2", {  opacity:0})

  //     footerTl.from(".icon3", { opacity:0})
  //     footerTl.from(".icon3Text1", { opacity:0})
  //     footerTl.from(".icon3Text2", { opacity:0})
  //     footerTl.from(".icon3Text3", {  opacity:0})
  //     footerTl.from(".icon3Text4", {  opacity:0 })

  //     footerTl.from(".icon4", { opacity:0})
  //     footerTl.from(".icon4Text1", { opacity:0})
  //     footerTl.from(".icon4Text2", { opacity:0})

  //     footerTl.from(".contactText", { opacity:0})
  //     footerTl.from(".contactInput1", { opacity:0})
  //     footerTl.from(".contactInput2", { opacity:0})
  //     footerTl.from(".contactInput3", { opacity:0,})
  //     footerTl.from(".contactInput4", { opacity:0})

  //     footerTl.from(".footerLogo", { opacity:0})
  //     footerTl.from(".social1", { opacity:0})
  //     footerTl.from(".social2", { opacity:0})
  //     footerTl.from(".social3", { opacity:0})

      


      
      

  //   },[footerContainer]
  // )
  return (
    
    // <div  className="footerContainer w-full bg-black  px-8 md:px-16 lg:px-36  flex  py-20 md:space-x-36 flex-col border-t border-white relative">

    //   <div className='w-full  '>
    //     <div className="grid grid-cols-1 md:grid-cols-3  gap-4 h-full space-y-10 md:space-y-0">
    //       <img
    //         src="images/logos/logo.png"
    //         className="h-10 md:h-12 block md:hidden "
    //         alt="logo"
    //       />
    //       <div className="row-span-2  h-full  space-y-10">
    //         <div className='flex space-x-5 flex-col'>
    //           <div className='flex  space-x-5'>
    //             <div className='h-10 w-10  icon1 flex justify-center items-end'>
    //               <img
    //                     src="images/footer-svg/phone.svg"
    //                     className="h-8 social1"
    //                     alt="facebook"
    //                   />
    //             </div>
    //             <div>
    //               <h5 className='icon1Text1 font-bold text-white'>Telefon</h5>
    //               <h6 className='icon1Text2 text-white'>+90 258 371 83 00</h6>
    //               <h6 className='icon1Text3 text-white'>+90 258 371 83 02</h6>
    //             </div>
    //           </div>

    //         </div>
    //         <div className='flex space-x-5 flex-col'>
    //           <div className='flex  space-x-5'>
    //           <div className='h-10 w-10  icon1 flex justify-center items-end'>
    //               <img
    //                     src="images/footer-svg/mail.svg"
    //                     className="h-8 social1"
    //                     alt="facebook"
    //                   />
    //             </div>
    //             <div>
    //               <h5 className='font-bold text-white icon2Text1'>Email</h5>
    //               <h6 className='text-white icon2Text2'>inceoglun@inceoglun.com.tr</h6>
    //             </div>
    //           </div>

    //         </div>
    //         <div className='flex space-x-5 flex-col '>
    //           <div className='flex  space-x-5'>
    //           <div className='h-10 w-10  icon1 flex justify-center items-end'>
    //               <img
    //                     src="images/footer-svg/location.svg"
    //                     className="h-8 social1"
    //                     alt="facebook"
    //                   />
    //             </div>
    //             <div>
    //               <h5 className='font-bold text-white icon3Text1'>Adress</h5>
    //               <h6 className='text-white icon3Text2'>Bozburun Mah.</h6>
    //               <h6 className='text-white icon3Text3'>7001. Sokak No:3,</h6>
    //               <h6 className='text-white icon3Text4'>Merkezefendi Denizli/TÜRKİYE</h6>
    //             </div>
    //           </div>

    //         </div>
    //         <div className='flex space-x-5 flex-col'>
    //           <div className='flex  space-x-5'>
    //           <div className='h-10 w-10  icon1 flex justify-center items-end'>
    //               <img
    //                     src="images/footer-svg/clock.svg"
    //                     className="h-8 social1"
    //                     alt="facebook"
    //                   />
    //             </div>
    //             <div>
    //               <h5 className='font-bold text-white icon4Text1'>Çalışma saatleri</h5>
    //               <h6 className='text-white icon4Text1'>Pazartesi - Cumartesi 08:30 - 17:30</h6>
    //             </div>
    //           </div>

    //         </div>
    //       </div>



      
    //       <div className="col-span-2 row-span-2  space-y-5 hidden md:block">
    //         <div className="grid grid-cols-2 grid-rows-1 gap-4">
    //           <div className=' w-full h-full  flex-col space-y-5'>
    //             <h5 className='text-white font-bold text-lg contactText'>İletişime Geçin</h5>
    //             <input className='w-full h-[2.25rem] contactInput1' />
    //             <input className='w-full h-[2.25rem] contactInput2' />
    //             <input className='w-full h-[2.25rem] contactInput3' />

    //             <input className='w-full h-[6.75rem] contactInput4' />

    //           </div>

    //           <div className=' w-full h-full flex justify-end'>
    //             <div className='w-36 h-full flex flex-col justify-between items-end'>
    //               <img
    //                 src="images/logos/logo.png"
    //                 className="h-10 md:h-12 footerLogo"
    //                 alt="logo"
    //               />
    //               <div className='  flex space-x-5 mb-10'>
    //                 <img
    //                   src="images/social/facebook.svg"
    //                   className="h-6 social1"
    //                   alt="facebook"
    //                 />
    //                 <img
    //                   src="images/social/instagram.svg"
    //                   className="h-6 social2"
    //                   alt="instagram"
    //                 />
    //                 <img
    //                   src="images/social/in.svg"
    //                   className="h-6 social3"
    //                   alt="facebook"
    //                 />

    //               </div>


    //             </div>

    //           </div>
    //         </div>


    //         <div className='h-10 w-full  flex justify-between items-end'>
    //           <h5 className='text-white text-xs'>Tüm Hakları İnceoğlu Makina Ticaret ve Sanayi A.Ş.’ye aittir.</h5>
    //           <h5 className="text-white text-xs font-['Merriweather Sans']">© 2023 İNCEOĞLUN</h5>
    //         </div>

    //       </div>


    //       <div className='h-10 w-full md:hidden flex flex-col  items-center space-y-5'>
    //         <div className='flex space-x-5'>
    //           <img
    //             src="images/social/facebook.svg"
    //             className="h-4"
    //             alt="facebook"
    //           />
    //           <img
    //             src="images/social/instagram.svg"
    //             className="h-4"
    //             alt="instagram"
    //           />
    //           <img
    //             src="images/social/in.svg"
    //             className="h-4"
    //             alt="facebook"
    //           />
    //         </div>
    //         <h5 className='text-white text-[0.7rem] md:text-xs'>Tüm Hakları İnceoğlu Makina Ticaret ve Sanayi A.Ş.’ye aittir.</h5>
    //         <h5 className="text-white text-xs font-['Merriweather Sans']">© 2023 İNCEOĞLUN</h5>
    //       </div>
    //     </div>
    //   </div>


    // </div>

    <div className='h-52 w-full  container px-8 md:px-16 lg:px-36 bg-black z-20'>
      <div className='w-full h-full  flex items-center justify-between'>
      <Link to={'/'}>
        <img
          src="images/logos/logo.png"
          className="h-10 md:h-12 cursor-pointer logo"
          alt="logo"
          
        />
        </Link>

        <div className='flex flex-col items-end space-y-2'>
                <div className='flex space-x-5 mb-5'>
              <Link >
             <img
                src="images/social/facebook.svg"
                className="h-6"
                alt="facebook"
              />
              </Link>
              <Link>
              <img
                src="images/social/instagram.svg"
                className="h-6"
                alt="instagram"
              />
              </Link>
              <Link>
              <img
                src="images/social/in.svg"
                className="h-6"
                alt="facebook"
              />
              </Link>
            </div>
            <h5 className='text-white'>Tüm Hakları İnceoğlu Makina Ticaret ve Sanayi A.Ş.’ye aittir.</h5>
            <h4  className='text-white'>© 2023 İNCEOĞLUN</h4>
        </div>
      </div>
    </div>
  
  )
}

export default Footer