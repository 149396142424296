import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

const Header = ({ menuType, setMenuType, headerTransparent }) => {
  const headerContainer = useRef()

  useGSAP(
    () => {

      gsap.from(".line1", {

        opacity: 0,
        duration: 1,
        delay: 1,
        y: 16



      });
      gsap.from(".line2", {

        x: 400,
        duration: 1

      });
      gsap.from(".line3", {

        opacity: 0,
        duration: 1,
        delay: 1,
        y: -16

      });

      gsap.from(".logo", {

        scale: 0.9,
        opacity: 0,
        duration: 2,
        x: 20,



      });


      gsap.from(".li-1", {

        opacity: 0,
        duration: 2,
        delay: 0.5


      });

      gsap.from(".li-2", {

        opacity: 0,
        duration: 3,
        delay: 1



      });

      gsap.from(".li-3", {

        opacity: 0,
        duration: 4,
        delay: 1.5


      });


      // gsap.from(".logo-degirmen", {

      //   scale:0.9,
      //   opacity:0,
      //   duration:2,
      //   x:20,



      // });


      gsap.from(".logo-degirmen", {

        scale: 0.95,

        opacity: 0.8,
        repeat: -1,
        yoyo: 1,
        duration: 1



      });



    }, [headerContainer]
  )


  return (
    <header ref={headerContainer} className={`h-20 md:h-28 w-full px-8 md:px-16 lg:px-36 fixed z-30  ${(menuType || headerTransparent) ? 'bg-transparent' : 'bg-black'}`}>
      <div className="w-full  mx-auto h-full  flex items-center justify-between">
        <Link to={'/'}>
          <img
            src="images/logos/logo.png"
            className="h-10 md:h-12 cursor-pointer logo"
            alt="logo"

          />
        </Link>
        <div className='flex justify-center items-center space-x-2 md:space-x-12'>
          <div className=" items-center h-full space-x-12 hidden lg:flex">
            <Link to={'history'}>
              <h6 className="font-bold text-sm text-white cursor-pointer li-1  hover:scale-110">
                Kurumsal
              </h6>
            </Link>
            <Link to={'home37'}><h6 className="font-bold text-sm text-white cursor-pointer li-2">Faaliyet Alanları</h6></Link>
            <Link to={'home38'}><h6 className="font-bold text-sm text-white cursor-pointer li-3">Ürünler</h6></Link>
            <Link to={'insankaynaklari'}><h6 className="font-bold text-sm text-white cursor-pointer li-3">İnsan Kaynakları</h6></Link>
          </div>
          <Link to={'/'}>
            <img
              src="images/logos/logo2.png"
              className="h-14 md:h-20 logo-degirmen shadow-lg"
              alt="logo"
            />
          </Link>

          <div onClick={() => setMenuType(true)} className={`h-6 md:h-8 w-10  group   flex flex-col justify-between cursor-pointer ${menuType ? 'invisible' : 'visible'}  `} >
            <div className='line1  w-full h-[0.125rem]  bg-white '></div>
            <div className='line2  w-full h-[0.125rem] bg-white'></div>
            <div className='line3  w-full h-[0.125rem] bg-white'></div>
          </div>
        </div>
      </div>

    </header>
  )
}

export default Header