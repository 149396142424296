import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
// // import './App.css';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useEffect, useLayoutEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Header from "./components/Header";
import Slider from "./components/Slider";
import Footer from "./components/Footer";
import Menu from "./components/Menu";
import { useState } from "react";
import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import Home from "./pages/Home";
import Home33 from "./pages/Home33";
import History from "./pages/History";
import Home38 from "./pages/Home38";
import Home37 from "./pages/Home37";
import { Route, Routes, useLocation, useNavigate, useParams, useRoutes } from "react-router-dom";
import Products from "./pages/Products";
import Home41 from "./pages/Home41";
import Home40 from "./pages/Home40";
import Iletisim from "./pages/Iletisim";
import { Home44 } from "./pages/Home44";
import InsanKaynakları from "./pages/InsanKaynakları";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.defaults({
  toggleActions: "restart pause resume pause",
});

function App() {
  const [menuType, setMenuType] = useState(false);
  const [headerTransparent, setHeaderTransparent] = useState(false)
  const [footer, setFooter] = useState(true)
  let location = useLocation();

console.log(location)

useLayoutEffect(() => {
  window.scrollTo(0, 0);
  setMenuType(false)


  if(location.pathname === '/home37'){
    setFooter(false)
  }else{{{
    setFooter(true)
  }}}

  

  
}, [location])

useGSAP(
  () => {
    gsap.set(".ball", {xPercent: -50, yPercent: -50});

    let xTo = gsap.quickTo(".ball", "x", {duration: 0.6, ease: "power3"}),
        yTo = gsap.quickTo(".ball", "y", {duration: 0.6, ease: "power3"});
    
    window.addEventListener("mousemove", e => {
      xTo(e.clientX);
      yTo(e.clientY);
    });



    
    
  })


  return (
    <ReactLenis root >
      <div className="App  mx-auto bg-black overflow-x-hidden  relative hidden md:block ">
        {
          menuType&&<Menu menuType={menuType} setMenuType={setMenuType} />
        }
        <div class="ball"></div>
        <Header menuType={menuType} setMenuType={setMenuType} headerTransparent={headerTransparent} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="history" element={<History />} />
          <Route path="home33" element={<Home33 setHeaderTransparent={setHeaderTransparent}/>} />
          
          <Route path="home37" element={<Home37 setHeaderTransparent={setHeaderTransparent}/>} />
          
          <Route path="home38" element={<Home38 />} />
          <Route path="home40" element={<Home40 />} />
          <Route path="home41" element={<Home41 />} />
          <Route path="home44" element={<Home44 />} />
          <Route path="insankaynaklari" element={<InsanKaynakları setHeaderTransparent={setHeaderTransparent}/>}/>
          <Route path="Iletisim" element={<Iletisim />}/>

          {/* <Route path="products" element={<Products />} /> */}
        </Routes>
        {footer&&<Footer />}
      </div>
    </ReactLenis>
  );
}

export default App;
